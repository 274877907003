import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { eventService } from '../domain/services/eventService';
import { appendInlineScript } from '../domain/utils/domUtils';
import { WithCmpSettingsNonBlocking } from './WithCmpSettingsNonBlocking';
import {
  addAttributeToTag,
  findTags,
  getExternalLinks
} from '../domain/utils/stringUtils';
import { conf, t } from '../domain/services/configService';
import { theme } from '../domain/theme';

const optanonLink = conf.menus.menuFooter.find(
  link => link.class === 'ot-sdk-show-settings'
);

const placeholder = `<div class="yt-embed" style="margin:28px;">
<div class="aspect-ratio-wrapper sixteennine">
</div>
</div>`;

const consentRequired = `<div class="yt-embed with-border" style="margin:28px;">
<div class="aspect-ratio-wrapper sixteennine">
  <p>${t('You need to consent to YouTube cookies to watch this.')}</p>
  <div><button type="button" tabindex="0" onclick="window.Optanon.ToggleInfoDisplay()" style="background: unset;color: #333;border: unset;border-bottom: 2px solid #fc0;">${
    optanonLink ? optanonLink.title : t('Cookie Settings')
  }</button></div>
</div>
</div>`;

const style = {
  youtube: css`
    .yt-embed {
      padding-bottom: 56.25%;
      height: 0;
      max-width: 100%;
      overflow: visible;
      position: relative;
      text-align: center;
      margin-bottom: 30px;
      iframe {
        height: 100%;
        left: 0;
        min-height: 100%;
        position: absolute;
        top: 0;
        vertical-align: middle;
        width: 100%;
      }
    }
    .with-border {
      border: solid 4px #000;
      p {
        padding-top: 10%;
        ${theme.mq.tablet} {
          padding-top: 25%;
        }
        font-weight: 400;
        font-family: Georgia, Times, 'Times New Roman', serif;
        font-size: 1.1875rem;
        line-height: 1.9375rem;
        letter-spacing: normal;
      }
    }
  `
};

const replaceYoutube = (html, replaceWith) => {
  let replacedHtml = html;
  const iFrames = findTags(html, 'iframe');
  iFrames.forEach(iframe => {
    if (iframe.openingTag.includes('src="https://www.youtube.com/embed/')) {
      replacedHtml = replacedHtml.replace(iframe.fullTag, replaceWith);
    }
  });
  return replacedHtml;
};

const BlankTemplateContentInner = ({ body, script, cmpSettings }) => {
  let bodyField = body;
  useEffect(() => {
    const links = document.querySelectorAll('.event-trigger-link');
    for (let i = 0; i < links.length; i += 1) {
      links[i].addEventListener('click', e => {
        e.preventDefault();
        const link = e.target || e.srcElement;
        eventService.dispatchEvent(link.dataset.event);
      });
    }
  }, []);

  const tagRef = useRef(null);
  useEffect(() => {
    if (script) {
      appendInlineScript(tagRef.current, script);
    }
  }, []);

  if (!cmpSettings) {
    // When doing SSR, remove all YouTUbe videos and use a placeholder to avoid CLS problems.
    bodyField = replaceYoutube(bodyField, placeholder);
  } else if (
    // When cmsSettings are available, but cookies not accepted, show the consent block.
    !cmpSettings.cmpTargeting ||
    !cmpSettings.cmpPerformance ||
    !cmpSettings.service_loaded
  ) {
    bodyField = replaceYoutube(bodyField, consentRequired);
  }
  // Add rel="noopener noreferrer nofollow" to external links
  const externalLinks = getExternalLinks(bodyField);

  externalLinks.forEach(link => {
    const originalOpeningTag = link.openingTag;
    addAttributeToTag(link, 'rel', ['noopener', 'noreferrer', 'nofollow']);
    bodyField = bodyField.replace(originalOpeningTag, link.openingTag);
  });

  return (
    <div
      ref={tagRef}
      css={style.youtube}
      id="blank-template-custom-body"
      className="node__cont" // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: bodyField }}
    />
  );
};

BlankTemplateContentInner.defaultProps = {
  script: undefined,
  cmpSettings: undefined
};

BlankTemplateContentInner.propTypes = {
  body: PropTypes.string.isRequired,
  script: PropTypes.string,
  cmpSettings: PropTypes.objectOf(PropTypes.any)
};

export const BlankTemplateContent = WithCmpSettingsNonBlocking(
  BlankTemplateContentInner
);
